import * as client_hooks from '../../../src/hooks.client.ts';
import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35')
];

export const server_loads = [];

export const dictionary = {
		"/(app)": [18,[2],[3]],
		"/(app)/add-ons": [19,[2,4],[3,5]],
		"/(app)/add-ons/runs": [22,[2,4],[3,5]],
		"/(app)/add-ons/[owner]/[repo]": [20,[2,4],[3,5,6]],
		"/(app)/add-ons/[owner]/[repo]/[event]": [21,[2,4],[3,5,6]],
		"/(app)/app": [23,[2],[3]],
		"/(app)/documents": [~24,[2,7],[3,8]],
		"/(app)/documents/[id]-[slug]": [~25,[2,7,9],[3,8,10]],
		"/embed/documents/[id]-[slug]": [34,[17]],
		"/embed/documents/[id]/annotations/[note_id]": [32],
		"/embed/documents/[id]/pages/[page]": [33],
		"/embed/projects/[project_id]-[slug]": [35],
		"/(pages)/home": [31,[],[16]],
		"/(app)/projects": [~26,[2,11],[3,12]],
		"/(app)/projects/[id]-[slug]": [28,[2,11],[3,12,13]],
		"/(app)/projects/[id]": [27,[2,11],[3,12]],
		"/(app)/upload": [~29,[2,14],[3,15]],
		"/(pages)/[...path]": [30,[],[16]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: universal_hooks.reroute || (() => {})
};

export { default as root } from '../root.svelte';